<template>
  <div class="my-10">
    <div class="my-5">
      <router-link :to="{ name: 'products',
        params: { companyUrl: company.url, uuid: company.uuid } }" class="flex items-center text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
            clip-rule="evenodd" />
        </svg>
        <span class="text-xs md:text-sm"> ir para página inicial</span>
      </router-link>
    </div>
    <div class="font-sans container">
      <div class="w-full md:w-3/5 mx-auto p-8">
        <h2 class="text-gray-800 font-semibold text-2xl text-center">
          Perguntas frequentes
        </h2>
        <div class="shadow-md mt-5">
          <div class="tab w-full overflow-hidden border-t">
            <input class="absolute opacity-0" id="tab-multi-one" type="checkbox" name="tabs" />
            <label class="block p-5 leading-normal cursor-pointer" for="tab-multi-one">Preciso me cadastrar?</label>
            <div class="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              ">
              <p class="p-5">
                Sim. para conseguir efetuar uma compra, é preciso realizar seu
                cadastro, é bem simples, rápido e seguro.
              </p>
            </div>
          </div>

          <div class="tab w-full overflow-hidden border-t">
            <input class="absolute opacity-0" id="tab-multi-two" type="checkbox" name="tabs" />
            <label class="block p-5 leading-normal cursor-pointer" for="tab-multi-two">Quero alterar dados da
              conta</label>
            <div class="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              ">
              <p class="p-5">
                Clicando no icone no topo, você tem acesso no menu para editar
                seus dados, é bem simples, rápido e seguro
              </p>
            </div>
          </div>

          <div class="tab w-full overflow-hidden border-t">
            <input class="absolute opacity-0" id="tab-multi-three" type="checkbox" name="tabs" />
            <label class="block p-5 leading-normal cursor-pointer" for="tab-multi-three">Posso comprar mais em 2
              restaurantes ao mesmo tempo</label>
            <div class="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              ">
              <p class="p-5">
                Não! Você pode fazer pedido apenas para uma restaurante por vez.
                Não poderá ter mais itens de uma restaurante no mesmo pedido.
              </p>
            </div>
          </div>

          <div class="tab w-full overflow-hidden border-t">
            <input class="absolute opacity-0" id="tab-multi-four" type="checkbox" name="tabs" />
            <label class="block p-5 leading-normal cursor-pointer" for="tab-multi-four">Pagamentos</label>
            <div class="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              ">
              <p class="p-5">
                No momento não será possível realziar pagamento direto no site,
                você escolhe a forma de pagamento e no momento da entrega
                realiza o pagamento diretamente com a restaurante. As opções de
                pagamentos é definida por cada restaurante.
              </p>
            </div>
          </div>

          <div class="tab w-full overflow-hidden border-t">
            <input class="absolute opacity-0" id="tab-multi-five" type="checkbox" name="tabs" />
            <label class="block p-5 leading-normal cursor-pointer" for="tab-multi-five">Quanto tempo chega meu
              pedido?</label>
            <div class="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              ">
              <p class="p-5">
                Primeiramente precisamos deixar claro que nós do putzfome somos
                responsáveis pela transmissão do pedido para o restaurante. A
                produção e entrega é majoritariamente responsabilidade do local
                onde fez o pedido.
              </p>
            </div>
          </div>

          <div class="tab w-full overflow-hidden border-t">
            <input class="absolute opacity-0" id="tab-multi-six" type="checkbox" name="tabs" />
            <label class="block p-5 leading-normal cursor-pointer" for="tab-multi-six">Posso cancelar um pedido?</label>
            <div class="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              ">
              <p class="p-5">
                Sim, para isso, você pode entrar em contato direto com o
                restaurante para cancelar, o lojista então irá realizar a
                operação de cancelamento no painel e em alguns minutos irá
                aparecer no seu painel pedido cancelado. Telefone você encontra
                no seu pedido e também na página do restaurante na opção "ver
                mais"
              </p>
            </div>
          </div>

          <div class="tab w-full overflow-hidden border-t">
            <input class="absolute opacity-0" id="tab-multi-seven" type="checkbox" name="tabs" />
            <label class="block p-5 leading-normal cursor-pointer" for="tab-multi-seven">Meu pedido foi
              confirmado?</label>
            <div class="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              ">
              <p class="p-5">
                Acessando menu na opção pedido, você encontra todos os pedidos,
                selecione o atual e terá acesso a todas informações do pedido. O
                Status será atualizado automaticamente a cada atualização do
                restaurante.
              </p>
            </div>
          </div>

          <div class="tab w-full overflow-hidden border-t">
            <input class="absolute opacity-0" id="tab-multi-eight" type="checkbox" name="tabs" />
            <label class="block p-5 leading-normal cursor-pointer" for="tab-multi-eight">Não achei minha dúvida</label>
            <div class="
                tab-content
                overflow-hidden
                border-l-2
                bg-gray-100
                border-indigo-500
                leading-normal
              ">
              <p class="p-5">
                Não encontrou sua dúvida aqui? Fique tranquilo, nos mande um
                e-mail para nosso suporte que teremos o prazer em lhe ajudar a
                esclarecer todas as suas duvidas, combinado? Então nos envie
                suporte@putzfome.com.br
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      company: (state) => state.companies.companySelected,

    }),
  },
}

</script>

<style scoped>
/* Tab content - closed */
.tab-content {
  max-height: 0;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
}

/* :checked - resize to full height */
.tab input:checked~.tab-content {
  max-height: 100vh;
}

/* Label formatting when open */
.tab input:checked+label {
  /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
  font-size: 0.9rem;
  /*.text-xl*/
  padding: 1.25rem;
  /*.p-5*/
  border-left-width: 2px;
  /*.border-l-2*/
  border-color: #6574cd;
  /*.border-indigo*/
  background-color: #f8fafc;
  /*.bg-gray-100 */
}

/* Icon */
.tab label::after {
  float: right;
  right: 0;
  top: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  font-size: 1.25rem;
  text-align: center;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

/* Icon formatting - closed */
.tab input[type="checkbox"]+label::after {
  content: "+";
  font-weight: bold;
  /*.font-bold*/
  border-width: 0px;
  /*.border*/
  border-radius: 9999px;
  /*.rounded-full */
  border-color: #b8c2cc;
  /*.border-grey*/
}

.tab input[type="radio"]+label::after {
  content: "\25BE";
  font-weight: bold;
  /*.font-bold*/
  border-width: 1px;
  /*.border*/
  border-radius: 9999px;
  /*.rounded-full */
  border-color: #b8c2cc;
  /*.border-grey*/
}

/* Icon formatting - open */
.tab input[type="checkbox"]:checked+label::after {
  transform: rotate(315deg);
  background-color: #ffb512;
  /*.bg-indigo*/
  color: #f8fafc;
  /*.text-grey-lightest*/
}

.tab input[type="radio"]:checked+label::after {
  transform: rotateX(180deg);
  background-color: #ffb512;
  /*.bg-indigo*/
  color: #f8fafc;
  /*.text-grey-lightest*/
}

p {
  @apply text-sm;
}
</style>